<template>
    <div class="p-t-5 p-l-5">
        <Divider dashed style="margin: 10px 0 5px 0;"><span class="divider-text">数据汇总</span></Divider>

        <p>
            <span class="title">汇总时间：</span>
            {{transactionReconciliationQuery.startDate}} 至 {{transactionReconciliationQuery.endDate}}
        </p>
        <Row class="text-center m-t-10 p-t-5 p-b-5 transaction-content">
            <i-col span="24" class="text-16 p-b-10">
                已对账总额：<span class="text-orange">{{formatMoney(executeTotalBean.actual)}}</span>
            </i-col>
            <i-col span="12">
                <p class="title">未对账总额</p>
                {{formatMoney(executeTotalBean.target-executeTotalBean.actual)}}
            </i-col>
            <i-col span="12">
                <p class="title">全部合同执行额</p>
                {{formatMoney(executeTotalBean.target)}}
            </i-col>
        </Row>

        <div v-for="(item,index) in executeAmout" :key="index">
            <h4 class="p-t-20">{{item.name}}</h4>
            <Row class="title">
                <i-col span="8">已对账</i-col>
                <i-col span="8">未对账</i-col>
                <i-col span="8">合计</i-col>
            </Row>
            <Row>
                <i-col span="8">{{formatMoney(item.confirmAmount)}}</i-col>
                <i-col span="8">{{formatMoney(item.totalAmount-item.confirmAmount)}}</i-col>
                <i-col span="8">{{formatMoney(item.totalAmount)}}</i-col>
            </Row>
        </div>

    </div>
</template>

<script>

import { countContractFeeAmount } from '@/api/statement/confirm'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      query: {
        companyId: 0,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: '2020-01-01',
        endDate: new Date().getFullYear() + '-12-31',
        queryString: ''
      },
      executeAmout: []
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      countContractFeeAmount(this.query).then(res => {
        this.executeAmout = res
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.reconciliationDemo.beginUpdate
    },
    transactionReconciliationQuery () {
      return this.$store.state.reconciliationDemo.transactionReconciliationQuery
    },
    executeTotalBean () {
      const totalBean = {
        target: 0,
        actual: 0
      }

      this.executeAmout.forEach(element => {
        totalBean.target += element.totalAmount
        totalBean.actual += element.confirmAmount
      })

      return totalBean
    }
  },
  watch: {
    beginUpdate () {
      this.query.companyId = this.transactionReconciliationQuery.companyId
      this.query.startDate = this.transactionReconciliationQuery.startDate
      this.query.endDate = this.transactionReconciliationQuery.endDate
      this.query.queryString = this.transactionReconciliationQuery.queryString

      this.initPageData()
    }
  }
}
</script>
